"use client";

import { OrbitControls, Text } from "@react-three/drei";
import { Model as Note } from "@/components/Models/Note";
import VideoPlane from "./VideoPlane";
import TexturedPlane from "./TexturedPlane";
import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { useThree, useFrame } from "@react-three/fiber";
import Floor from "./Floor";
import { Model } from "./Models/Room";
import { textItems } from "./sceneAssets";

export default function Experience({
  triggerAnimation,
  zoomedIn,
  stopRotation,
  setZoomedIn,
  animationPlaying,
  setAnimationPlaying,
  zoomSfx
}) {
  const { camera } = useThree();

  // Ref for the group
  const groupRef = useRef();
  const orbitControlsRef = useRef();

  // State for mobile view
  const [isMobile, setIsMobile] = useState(false);

  // Function to normalize rotation values to 0–2π radians
  const normalizeRotation = (angle) => {
    return ((angle % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
  };

  // Trigger camera animation when the button is clicked
  useEffect(() => {
    const _isMobile = window.innerWidth < 768;
    setIsMobile(_isMobile);

    camera.position.set(20.766, 4, _isMobile ? 50 : 35); // Reset camera position
    camera.rotation.set(-0.2, 0, 0); // Reset camera rotation
  }, []);

  // Zoom in animation for the group
  useEffect(() => {
    if (zoomedIn) {
      gsap.to(groupRef.current.rotation, {
        y: 0.8, // Target rotation for zoomed-in view
        x: 0,
        z: 0,
        duration: 2,
        ease: "power2.out",
      });
    }
  }, [zoomedIn]);
  
  
  // Trigger animation for the group
  useEffect(() => {
    if (triggerAnimation) {
      gsap.to(groupRef.current.position, {
        y: 0,
        x: 0,
        z: 0,
        duration: 1.5,

        ease: "power2.inOut",
      });
    }
  }, [triggerAnimation]);

  // Rotation logic in `useFrame`
  useFrame((state, delta) => {
    if (!stopRotation && !zoomedIn && !animationPlaying) {
      const rotationSpeed = 0.2; // Rotation speed
      groupRef.current.rotation.y += rotationSpeed * delta;
      groupRef.current.rotation.y = normalizeRotation(groupRef.current.rotation.y);

      // Log current auto-rotation
      // console.log("Auto rotation (degrees):", (groupRef.current.rotation.y * 180) / Math.PI);
    }
  });

  // Monitor OrbitControls rotation
  useEffect(() => {
    if (orbitControlsRef.current) {
      const controls = orbitControlsRef.current;

      controls.addEventListener("change", () => {
        const currentRotation = groupRef.current.rotation.y;
        groupRef.current.rotation.y = normalizeRotation(currentRotation);

        // Log rotation from OrbitControls
        // console.log("OrbitControls rotation (degrees):", (currentRotation * 180) / Math.PI);
      });

      return () => {
        controls.removeEventListener("change", () => {});
      };
    }
  }, []);

  return (
    <>
      {/* OrbitControls are disabled when stopRotation is true */}
      <OrbitControls
        ref={orbitControlsRef}
        enabled={stopRotation && !zoomedIn && !animationPlaying} // Disable controls when stopRotation is true
        enableDamping
        enablePan={false}
        target={[-1.9, 6, -7]}
        dampingFactor={0.055}
        rotateSpeed={1}
        minDistance={isMobile ? 45 : 25}
        maxDistance={isMobile ? 60 : 50}
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 3}
      />
      <Floor isMobile={isMobile} />
      <group ref={groupRef} rotation={[0, 0.8, 0]} position={[30, 0, 10]}>
        <TexturedPlane isMobile={isMobile} />
        <VideoPlane
          videoUrl={
            "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/bilder/Tekken8Hworang.mp4"
          }
          position={[-2.67, 4.62, -13.1]}
          rotation={[0.617, Math.PI, 0]}
          dimensions={[2.55, 3.2]}
        />
        <VideoPlane
          videoUrl='https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/bilder/Billard.mp4'
          position={[2.3, 7.64, -10.61]}
          rotation={[0, -Math.PI, 0]}
          dimensions={[4.8, 2.27]}
        />

        {textItems.map((item, index) => (
          <Text
            key={index}
            font={item.font}
            fontSize={item.fontSize}
            position={item.position}
            rotation-x={-Math.PI / 2}
          >
            {item.text}
          </Text>
        ))}

        <Model
          isMobile={isMobile}
          triggerAnimation={triggerAnimation}
          zoomedIn={zoomedIn}
          setZoomedIn={setZoomedIn}
          setAnimationPlaying={setAnimationPlaying}
          zoomSfx={() => zoomSfx()}
        />
        <Note />
      </group>
    </>
  );
}
