import React, { useMemo } from "react";
import { Typewriter } from "react-simple-typewriter";
import { TYPEWRITER_WORDS } from "./sceneAssets";

/**
 * LoadingScreen component displays a loading animation with an optional progress bar and typewriter effect.
 *
 * @param {Object} props - The component props.
 * @param {number} [props.progress] - The current progress percentage (0-100). If undefined, the progress bar is not displayed.
 *
 * @returns {JSX.Element} The rendered LoadingScreen component.
 */
function LoadingScreen({ progress }) {
  const typewriterConfig = useMemo(() => ({
    typeSpeed: 70,
    loop: true,
    deleteSpeed: 50,
    delaySpeed: 1000,
  }), []);

  return (
    <div className='flex flex-col items-center justify-center z-[12]'>
      <div className='loader z-[20]'></div>
      <div className='text-white mt-4 text-center z-[20] flex flex-col gap-1'>
        <div className='text-style'>
          <Typewriter words={TYPEWRITER_WORDS} {...typewriterConfig} />
        </div>
        {/* Optional: Fortschrittsanzeige */}
        {progress !== undefined && (
          <div className="w-full bg-gray-300 rounded h-2.5 mb-4">
            <div className="bg-blue-500 h-2.5 rounded" style={{ width: `${progress}%` }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default LoadingScreen;
