import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import holographicVertexShader from "@/components/shaders/holographic/vertex.glsl";
import holographicFragmentShader from "@/components/shaders/holographic/fragment.glsl";
import { ShaderMaterial, Color, DoubleSide, AdditiveBlending } from "three";
import { useFrame } from "@react-three/fiber";

export function Model(props) {
  const meshRef = useRef();
  const materialRef = useRef();
  const createHolographicMaterial = () => {
    return new ShaderMaterial({
      uniforms: {
        uTime: { value: 0 },
        uColor: { value: new Color("#70c1ff") },
      },
      vertexShader: holographicVertexShader,
      fragmentShader: holographicFragmentShader,
      side: DoubleSide,
      transparent: true,
      depthWrite: false,
      blending: AdditiveBlending,
    });
  };
  const { nodes, materials } = useGLTF("/models/note.glb");
  const holographicMaterial = createHolographicMaterial();
  materialRef.current = holographicMaterial;

  useFrame((state, delta) => {
    if (meshRef.current) meshRef.current.rotation.y += 0.5 * delta;

    if (materialRef.current) {
      materialRef.current.uniforms.uTime.value += delta;
    }
  });

  return (
    <group
      ref={meshRef}
      {...props}
      dispose={null}
      scale={2}
      position={[6.8, 6, -11.57]}
      rotation={[0, Math.PI, 0]}
    >
      <group position={[0, 0.5, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_6.geometry}
          material={holographicMaterial}
          position={[0, -0.5, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/note.glb");
