import { useRef, useEffect } from "react";

function useAudio(audioSrc, initialVolume = 0.1) {
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(audioSrc);
    audioRef.current.volume = initialVolume;

    const audio = audioRef.current;

    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [audioSrc, initialVolume]);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current
        .play()
        .catch((error) => {
          if (error.name !== "NotAllowedError") {
            console.error("Audio playback failed:", error);
          }
        });
    }
  };  

  const setVolume = (volume) => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  };

  return { playAudio, setVolume, audioRef }; 
}

export default useAudio;
