import React, { useRef } from "react";
import { FrontSide, RGBAFormat, SRGBColorSpace } from "three";
import { useVideoTexture } from "@react-three/drei";

const VideoPlane = ({ videoUrl, position, rotation, dimensions }) => {
  const videoRef = useRef(null);
  const videoTexture = useVideoTexture(videoUrl, {
    videoRef,
    format: RGBAFormat,
    encoding: SRGBColorSpace,
    playsInline: true,
    autoPlay: true,
    loop: true,
    muted: true,
  });

  return (
    <mesh ref={videoRef} position={position} rotation={rotation}>
      <planeGeometry args={dimensions} />
      <meshBasicMaterial
        attach='material'
        toneMapped={false}
        side={FrontSide}
        map={videoTexture}
        onUpdate={(self) => (self.needsUpdate = true)} 
      />
    </mesh>
  );
};

export default VideoPlane;
