import React, { useMemo } from "react";
import { MeshReflectorMaterial } from "@react-three/drei";

const FLOOR_ROTATION = [-Math.PI / 2, 0, 0];
const FLOOR_POSITION = [0, 0.35, 0];
const FLOOR_SIZE = [90, 90];

/**
 * Floor component that renders a reflective floor mesh.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isMobile - Flag indicating if the device is mobile to adjust the resolution.
 *
 * @returns {JSX.Element} The Floor component.
 */
export default function Floor({ isMobile }) {
  const reflectorConfig = useMemo(() => ({
    blur: [0, 0],
    mixBlur: 1,
    mixStrength: 5,
    mixContrast: 1,
    mirror: 1,
    depthScale: 0.006,
    minDepthThreshold: 0.75,
    maxDepthThreshold: 1,
    depthToBlurRatioBias: 0.25,
    reflectorOffset: 0.02,
    resolution: isMobile ? 512 : 1024,
  }), [isMobile]);

  return (
    <mesh rotation={FLOOR_ROTATION} position={FLOOR_POSITION}>
      <planeGeometry args={FLOOR_SIZE} />
      <MeshReflectorMaterial {...reflectorConfig} />
    </mesh>
  );
}
