import { useEffect } from "react";
import { Html, useProgress } from "@react-three/drei";
import toast from 'react-hot-toast';
import { detect } from 'detect-browser';
import LoadingScreen from "@/components/LoadingScreen";

const browser = detect();

const Loader = ({ setShowEnter }) => {

  const { progress, loaded, total } = useProgress();

  // Display the start button only when all assets are loaded
  useEffect(() => {
    console.log(loaded, total);
    if (loaded === total) {
      setShowEnter(true);
    }
  }, [loaded, total, setShowEnter]);

  useEffect(() => {
    if (browser && browser.name === 'firefox') {
      toast("Firefox doesn't support all features. Please use Chrome or Safari!", {
        duration: 10000,
        position: 'bottom-left'
      });
    }
  }, []);

  return (
    <Html
      center
      className={
        "!z-[12] h-screen w-screen flex items-center bg-black justify-center"
      }
    >
      <LoadingScreen />
    </Html>
  );
};

export default Loader;
