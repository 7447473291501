import React from "react";
import { Html } from "@react-three/drei";
import PropTypes from "prop-types";

const IFrameComponent = ({
  isIOS,
  zoomedIn,
  iframeVisible,
  zoomSpriteRef,
  isMobile,
  handleZoomSpriteClick,
  zoomMaterial,
}) => {
  return (
    <>
      {/* Render the iframe as a texture on the plane */}
      {iframeVisible && zoomedIn && (
        <>
          <Html
            scale={isMobile ? [0.12, 0.12, 0.12] : [0.179, 0.173, 0.179]}
            position={[isIOS ? -4.35 : -0.02, isIOS ? 2.3 : -0.1, 0.1]}
            transform={!isIOS}
            distanceFactor={isIOS ? 1.9 : 10}
            occlude
            zIndexRange={[0, 0]}
          >
            <iframe
              width={isMobile ? 915 : 1920}
              height={isMobile ? 1620 : 1080} 
              allowFullScreen
              className='iframe'
              src='https://www.ibrahimaydiner.de'
              title='Ibrahim Aydiner'
            />
          </Html>

          {/* Zoom sprite */}
          {!isMobile && (
            <sprite
              ref={zoomSpriteRef}
              material={zoomMaterial}
              position={[0, -2.9, 0.2]}
              scale={[0.5, 0.5, 0.5]}
              onClick={handleZoomSpriteClick}
              onPointerOver={() => {
                document.body.style.cursor = "pointer";
              }}
              onPointerOut={() => {
                document.body.style.cursor = "auto";
              }}
            />
          )}
        </>
      )}
    </>
  );
};

IFrameComponent.propTypes = {
  isIOS: PropTypes.bool.isRequired,
  zoomedIn: PropTypes.bool.isRequired,
  iframeVisible: PropTypes.bool.isRequired,
  zoomSpriteRef: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  handleZoomSpriteClick: PropTypes.func.isRequired,
  zoomMaterial: PropTypes.object.isRequired,
};

export default IFrameComponent;
