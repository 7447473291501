"use client";

import { useState, useEffect } from "react";
import { RefreshCwOff, RefreshCcw, Play, Pause } from "lucide-react";

const MusicPlayer = ({ stopRotation, setStopRotation, audioRef, volume, setVolume }) => {
  const [isSongPlaying, setIsSongPlaying] = useState(true);

  const song = "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/songs/M.mp3";

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  // Toggle play/pause
  const handleToggleMusic = () => {
    if (audioRef.current) {
      if (isSongPlaying) {
        audioRef.current.pause(); // Pause the audio
      } else {
        audioRef.current.play().catch((error) => console.error("Error playing audio:", error)); // Play the audio
      }
      setIsSongPlaying(!isSongPlaying); // Toggle the play state
    }
  };

  // Handle volume slider change and pass volume to parent (Home)
  const handleVolumeChange = (event) => {
    const volumeLevel = parseFloat(event.target.value);
    setVolume(volumeLevel);
  };

  // Handle stopping or resuming rotation
  const handleStopRotation = () => {
    setStopRotation(!stopRotation); // Toggle rotation on/off
  };

  return (
    <div className="absolute bottom-4 flex gap-2 justify-around items-center right-4 bg-white/10 p-2 rounded-lg">
      {/* Audio for background music */}
      <audio ref={audioRef} src={song} preload="auto" />

      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={handleVolumeChange}
      />
      <button
        className="bg-white/10 p-2 rounded-full w-10 h-10 flex justify-center items-center"
        onClick={handleStopRotation}
      >
        {stopRotation ? (
          <RefreshCcw color="white" className="h-4 w-4" />
        ) : (
          <RefreshCwOff color="white" className="h-4 w-4" />
        )}
      </button>
      <button
        className="bg-white/10 p-2 rounded-full w-10 h-10 flex justify-center items-center"
        onClick={handleToggleMusic} // Handle play/pause with one button
      >
        {isSongPlaying ? (
          <Pause color="white" className="h-4 w-4" />
        ) : (
          <Play color="white" className="h-4 w-4" />
        )}
      </button>
    </div>
  );
};

export default MusicPlayer;
