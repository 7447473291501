import React, { useMemo } from "react";
import { TextureLoader, SRGBColorSpace } from "three";
import { useLoader } from "@react-three/fiber";
import { textureUrls } from "./sceneAssets";

export default function TexturedPlane() {
  const textures = useLoader(TextureLoader, textureUrls);
  textures.forEach(texture => {
    texture.colorSpace = SRGBColorSpace;
  });

  const meshes = useMemo(() => [
    { rotation: [0, -Math.PI, 0], position: [3.5, 5, -10.64], size: [2.42, 2.35], textureIndex: 4 },
    { rotation: [0, -Math.PI, 0], position: [0.65, 1.4, -10.64], size: [1.5, 1.5], textureIndex: 3 },
    { rotation: [0, -Math.PI, 0], position: [0.65, 3.4, -10.64], size: [1.5, 1.5], textureIndex: 2 },
    { rotation: [0, -Math.PI, 0], position: [0.65, 5.4, -10.64], size: [1.5, 1.5], textureIndex: 1 },
    { rotation: [0, -Math.PI, 0], position: [3.5, 1.81, -10.64], size: [2.42, 2.35], textureIndex: 0 },
  ], []);

  return (
    <>
      {meshes.map((mesh, index) => (
        <mesh key={index} rotation={mesh.rotation} position={mesh.position}>
          <planeGeometry args={mesh.size} />
          <meshBasicMaterial
            map={textures[mesh.textureIndex]}
            color={[1.4, 1.4, 1.4]}
            toneMapped={false}
          />
        </mesh>
      ))}
    </>
  );
}
