export const texturePaths = {
  bakedFliesse:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/room/fliesse/BakedFloor.jpg",
  bakedVasen:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/room/vasen/BakedVasen.jpg",
  bakedTomFord:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/room/vasen/BakedTomFord.jpg",
  bakedBed:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/room/bett/BakedBed.jpg",
  bakedCarpet:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/room/fliesse/BakedCarpet.jpg",
  bakedSpiegel:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/room/stuff/BakedMirror.jpg",
  bakedStuhl:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/room/stuff/BakedStuhl.jpg",
  bakedSchrankGross:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/room/stuff/BakedSchrankGross.jpg",

  bakedTiles:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/wand/BakedTiles.jpg",
  bakedMarkise:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/wand/BakedMarkise.jpg",
  bakedArcade:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/arcade/BakedArcade.jpg",
  bakedArcadeButtons:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/arcade/BakedArcadeButtons.jpg",
  bakedDrinkAutomat:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedDrinks.jpg",
  bakedRedBullYellow:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedRedBullYellow.jpg",
  bakedRedBullGreen:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedRedBullGreen.jpg",
  bakedRedBullBlue:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedRedBullBlue.jpg",
  bakedRedBullDarkBlue:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedRedBullDarkBlue.jpg",
  bakedRedBullPurple:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedRedBullPurple.jpg",
  bakedCola:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedCocaCola.jpg",
  bakedFanta:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedFanta.jpg",
  bakedElephantBay:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedElephantBay.jpg",
  bakedKaramalz:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/drinks/BakedKaramalz.jpg",
  bakedJukebox:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/jukebox/BakedJukeBox.jpeg",
  bakedAlbum1:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/jukebox/BakedAlbum1.jpg",
  bakedAlbum2:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/jukebox/BakedAlbum2.jpg",
  bakedAlbum3:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/jukebox/BakedAlbum3.jpg",
  bakedTaschenlampe:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/jukebox/BakedTaschenlampe.png",
  bakedVentilMitte:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/wand/BakedVentilMitte.jpg",
  bakedSchild:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/wand/BakedSchild.jpg",
  bakedPictures:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/bilder/BakedPictures.jpg",
  bakedLed:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/wand/BakedLed.jpg",
  bakedOutside:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/wand/BakedOutside.jpg",
  bakedWallsOutside:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/wand/BakedWalls.jpg",

  bakedKeyboard:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/electronic/BakedKeyboard.jpg",
  bakedMacbook:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/electronic/BakedMacbook.jpg",
  bakedMousepad:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/stuff/BakedMousepad.jpg",
  bakedPC:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/electronic/BakedPC.jpg",
  bakedPCFan:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/electronic/BakedPCFan.jpg",
  bakedFanPart:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/electronic/BakedFanPart.jpg",
  bakedHeadset:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/electronic/BakedHeadset.jpg",
  bakedHeadsetHolder:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/stuff/BakedHeadsetHolder.jpg",
  bakedBluRay1:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/books/BakedBluRay1.jpg",
  bakedBluRay2:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/books/BakedBluRay2.jpg",
  bakedBluRay3:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/books/BakedBluRay3.jpg",
  bakedHardcover1:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/books/BakedHardcover1.jpg",
  bakedHardcover2:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/books/BakedHardcover2.jpg",
  bakedHardcover3:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/books/BakedHardcover3.jpg",
  bakedPlatte:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/books/BakedPlatte.jpg",
  bakedBear:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/figures/BakedBear.jpg",
  bakedGojoKlein:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/figures/BakedGojoKlein.jpg",
  bakedGojoGross:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/figures/BakedGojoGross.jpg",
  bakedSchraenke:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/stuff/BakedSchraenke.jpg",
  bakedTable:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/stuff/BakedTable.jpg",
  bakedTableStuff:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/stuff/BakedTableStuff.jpg",
  bakedPallet:
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/desk/books/BakedPlatte.jpg",
};

export const textureUrls = [
  "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/bilder/Portfolio1.jpg",
  "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/bilder/Portfolio2.jpg",
  "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/bilder/Portfolio3.jpg",
  "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/bilder/Portfolio4.jpg",
  "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/outside/bilder/Portfolio5.jpg",
];

const FONT_URLS = {
  medium: "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/fonts/sf-compact-display-med.woff",
  thin: "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/fonts/sf-compact-display-thin.woff",
};

export const textItems = [
  { text: "Ibrahim Aydiner", fontSize: 1, position: [-6.25, 0.4, 11], font: FONT_URLS.medium },
  { text: "Software-Engineer", fontSize: 0.75, position: [-6.75, 0.4, 12], font: FONT_URLS.thin },
  { text: "UI / UX Designer", fontSize: 0.75, position: [-7.15, 0.4, 13], font: FONT_URLS.thin },
  { text: "3D-Artist", fontSize: 0.75, position: [-8.26, 0.4, 14], font: FONT_URLS.thin },
];

export const TYPEWRITER_WORDS = [
  "Building Room...",
  "Please wait...",
  "Scene getting ready...",
];