import { useLoader } from "@react-three/fiber";
import { useMemo } from "react";
import { MeshBasicMaterial, SRGBColorSpace, TextureLoader } from "three";

export const createGlassMaterial = (opacity) => {
    return new MeshBasicMaterial({
      color: 0x000000,
      transparent: true,
      opacity,
      depthWrite: false,
    });
};
  
export const useBatchTextures = (paths) => {
    const textures = useLoader(TextureLoader, Object.values(paths));
    return useMemo(() => {
      return Object.keys(paths).reduce((acc, key, index) => {
        const texture = textures[index];
        texture.colorSpace = SRGBColorSpace;
        texture.flipY = false;
        acc[key] = texture;
        return acc;
      }, {});
    }, [paths, textures]);
};
  
export const isIOS = () => {
    if (typeof window === "undefined") return false;
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};