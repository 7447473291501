"use client";

import { Canvas } from "@react-three/fiber";
import Experience from "@/components/Experience";
import { Suspense, useRef, useState, useEffect } from "react";
import { Environment, Html } from "@react-three/drei";
import MusicPlayer from "@/components/MusicPlayer"; // Import MusicPlayer
import { twMerge } from "tailwind-merge";
import toast, { Toaster } from 'react-hot-toast';
import { detect } from 'detect-browser';
import useAudio from "../components/hooks/useAudio";
import Loader from "@/components/Loader";
import { Perf } from "r3f-perf";

const browser = detect();

const Home = () => {
  // Setting refs for video elements
  const videoRef = useRef();
  const videoRef2 = useRef();

  // Setting states for scene visibility, enter button visibility, trigger animation, zoomed in state, rotation state, zoom animation state, and volume
  const [sceneVisible, setSceneVisible] = useState(false);
  const [showEnter, setShowEnter] = useState(false);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [zoomedIn, setZoomedIn] = useState(false); 
  const [stopRotation, setStopRotation] = useState(true); 
  const [zoomAnimationPlaying, setZoomAnimationPlaying] = useState(false);
  const [volume, setVolume] = useState(0.1);

  // Setting the volume for the button sound, zoom sound, and background music
  useEffect(() => {
    setButtonSoundVolume(volume + 0.2);
    setZoomSoundVolume(volume + 0.15);
    setBackgroundMusicVolume(volume);
  }, [volume]);
  
  // Play the button sound, zoom sound, and background music
  const { playAudio: playButtonSound, setVolume: setButtonSoundVolume } = useAudio(
    'https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/songs/Sfx.mp3',
    volume
  );
  
  const { playAudio: playZoomSound, setVolume: setZoomSoundVolume } = useAudio(
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/songs/Whoosh.mp3",
    volume
  );

  const { playAudio: playBackgroundMusic, setVolume: setBackgroundMusicVolume, audioRef: backgroundAudioRef } = useAudio(
    "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/songs/M.mp3",
    volume
  );

  // handleStartClick function to trigger the scene
  const handleStartClick = () => {
    playButtonSound();
  
    setTimeout(() => {
      setSceneVisible(true);
      setTriggerAnimation(true);
      setStopRotation(false); 
    }, 200);
  
    setTimeout(() => {
      if (backgroundAudioRef.current) {
        playBackgroundMusic(); 
      }
    }, 2000);
  };  

  return (
    <div className='h-screen'>
      {/* Show a toast message if the browser is Firefox */}
      <Toaster />

      {/* Show the enter button if the scene is not visible */}
      {!sceneVisible && showEnter && (
        <div className='fixed enter-button z-[10] h-full w-full bg-black flex items-center justify-center '>
          <button
            id='start-button'
            onClick={handleStartClick}
            className='text-white neon'
          >
            Start
          </button>
        </div>
      )}

      {/* Show the return button if the scene is zoomed in */}
      <button
        onClick={() => {
          playButtonSound();
          setZoomAnimationPlaying(true);
          setZoomedIn(false);
        }}

        className={twMerge(
          "fixed right-5 top-5 z-[10] bg-black text-white px-5 py-2 rounded-md opacity-0 pointer-events-none transition-opacity duration-300 ease-in-out",
          zoomedIn && !zoomAnimationPlaying && "opacity-100 pointer-events-auto"
        )}
      >
        Return
      </button>
      
      {/* Canvas for the 3D scene */}
      <Canvas
        camera={{
          fov: 45,
          near: 0.1,
          far: 200,
        }}
        style={{ background: "black" }}
      >
        <Perf className="performanceTrack" />
        <ambientLight intensity={4} />
        <Environment files='https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/env/lebombo_1k.hdr' environmentIntensity={0.1} />

        {/* Show the loader if the scene is not visible */}
        <Suspense fallback={<Loader setShowEnter={setShowEnter} />}>
          <Experience
            animationPlaying={zoomAnimationPlaying}
            setAnimationPlaying={setZoomAnimationPlaying}
            zoomedIn={zoomedIn}
            setZoomedIn={setZoomedIn}
            videoRef={videoRef}
            videoRef2={videoRef2}
            triggerAnimation={triggerAnimation}
            stopRotation={stopRotation}
            zoomSfx={playZoomSound}
          />
        </Suspense>
      </Canvas>

      {/* Add MusicPlayer and control its play state */}
      <MusicPlayer
        stopRotation={stopRotation}
        setStopRotation={setStopRotation}
        audioRef={backgroundAudioRef}
        volume={volume}
        setVolume={setVolume}
      />
    </div>
  );
};

export default Home;
