"use client";

import React, { useEffect, useState, useRef } from "react";
import { useGLTF, useAnimations, Text } from "@react-three/drei";
import { useLoader, useThree } from "@react-three/fiber";
import { MeshBasicMaterial, SRGBColorSpace, Color } from "three";
import { SpriteMaterial, TextureLoader } from "three";
import { gsap } from "gsap";
import IFrameComponent from "./IFrameComponent";
import { texturePaths } from "../sceneAssets";
import { createGlassMaterial, isIOS, useBatchTextures } from "../sceneUtils";

export function Model({ zoomedIn, setZoomedIn, setAnimationPlaying, isMobile, zoomSfx }) {
  const group = useRef();
  const { camera } = useThree();

  const monitorRef = useRef(null); 
  const textRef = useRef();
  const zoomSpriteRef = useRef();

  const fontMedium = "https://my-portfolio-3d.s3.eu-central-1.amazonaws.com/fonts/sf-compact-display-med.woff";
  
  const [iframeVisible, setIframeVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false); 

  const { nodes, animations } = useGLTF("/models/SetupIbrahim2.glb");
  const { actions } = useAnimations(animations, group);

  const textures = useBatchTextures(texturePaths);
  const zoomTexture = useLoader(TextureLoader, "/zoom.png");
  const zoomMaterial = new SpriteMaterial({ map: zoomTexture, transparent: true });

  const glassMaterialOutside = createGlassMaterial(0.2);
  const glassMaterialPC = createGlassMaterial(0.5);

  useEffect(() => {
    if (textRef.current) {
      // Animate the text moving up and down
      gsap.to(textRef.current.position, {
        y: "+=1",
        repeat: -1,
        yoyo: true,
        duration: 1.5,
        ease: "power1.inOut",
      });
    }
  }, [zoomedIn]);

  useEffect(() => {
    if (!zoomedIn) {
      setTimeout(() => {
        setIframeVisible(false);
        setIsClicked(false);
        if (textRef.current) {
          textRef.current.material.transparent = true;
          textRef.current.material.opacity = 1;
        }
      }, 500);
      gsap.to(camera.position, {
        x: 20.766,
        y: 8,
        z: isMobile ? 50 : 35,
        delay: 0.5,
        duration: 2.5,
        ease: "power2.out",
        onUpdate: () => camera.lookAt(-1.9, 6, -7),
        onComplete: () => setAnimationPlaying(false),
      });
    }
  }, [zoomedIn]);

  useEffect(() => {
    if (zoomedIn && zoomSpriteRef.current) {
      gsap.to(zoomSpriteRef.current.position, {
        y: "+=0.1",
        repeat: -1,
        yoyo: true,
        duration: 1.5,
        ease: "power1.inOut",
      });
    }
  }, [zoomedIn]);

  useEffect(() => {
    if (actions) {
      Object.values(actions).forEach((action) => action.play());
      return () => Object.values(actions).forEach((action) => action?.stop());
    }
  }, [actions]);


  const handleMonitorClick = () => {
    if (isClicked || !monitorRef.current) return;

    zoomSfx();
    setZoomedIn(true);
    setIsClicked(true);

    gsap.to(camera.position, {
      x: isMobile ? 2.6 : 0,
      y: 6,
      z: isMobile ? -2.2 : -5.1,
      duration: 2,
      ease: "power2.inOut",
      onStart: () => {
        setAnimationPlaying(true);
        if (textRef.current) {
          textRef.current.material.transparent = true;
          textRef.current.material.opacity = 0;
        }
      },
      onUpdate: () => camera.lookAt(-1.9, 6, -7),
      onComplete: () => {
        setAnimationPlaying(false);
      },
    });

    setTimeout(() => {
      setIframeVisible(true);
    }, 2000);
  };

  // Function to zoom camera further on sprite click
  const handleZoomSpriteClick = () => {
    zoomSfx();
    
    gsap.to(camera.position, {
      x: -2.17,
      y: 5.8,
      z: -7.4,
      duration: 2,
      ease: "power2.inOut",
      onUpdate: () => camera.lookAt(-4.42, 5.8, -9.67),
    });
  };

  return (
    <group ref={group} dispose={null} scale={0.5}>
      <group name='Scene'>
        <group
          name='Sketchfab_model'
          position={[9, 1.666, -11]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={7.586}
        >
          <group name='root001'>
            <group name='GLTF_SceneRootNode001' rotation={[Math.PI / 2, 0, 0]}>
              <group name='RootNode_0'>
                <group name='pCylinder16001_1'>
                  <mesh
                    name='Object_5001'
                    geometry={nodes.Object_5001.geometry}
                    material={
                      new MeshBasicMaterial({
                        map: textures.bakedStuhl,
                      })
                    }
                    position={[0.791, 0, 0.264]}
                    rotation={[Math.PI, -1.134, Math.PI]}
                  />
                </group>
              </group>
            </group>
          </group>
        </group>
        {/* Bear */}
        <mesh
          name='Bear'
          geometry={nodes.Bear.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedBear,
            })
          }
          position={[8.421, 16.066, -19.287]}
          rotation={[0, 0.377, 0]}
          scale={0.285}
        />

        {/* Gojo */}
        <mesh
          name='Gojo'
          geometry={nodes.Gojo.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedGojoGross,
            })
          }
          position={[14.172, 16.85, -18.742]}
          rotation={[3.001, 0.497, -1.086]}
          scale={[-0.348, -0.281, -0.412]}
        />

        {/* BluRay1 */}
        <mesh
          name='BluRay1'
          geometry={nodes.BluRay1.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedBluRay1,
            })
          }
          position={[10.832, 15.978, -18.965]}
          rotation={[-Math.PI, 0.272, -Math.PI]}
          scale={[0.581, 0.767, 0.177]}
        />

        {/* BluRay2 */}
        <mesh
          name='BluRay2'
          geometry={nodes.BluRay2.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedBluRay2,
            })
          }
          position={[11.726, 15.978, -19.208]}
          rotation={[-Math.PI, 0.272, -Math.PI]}
          scale={[0.581, 0.767, 0.177]}
        />

        {/* BluRay3 */}
        <mesh
          name='BluRay3'
          geometry={nodes.BluRay3.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedBluRay3,
            })
          }
          position={[12.644, 15.978, -19.316]}
          rotation={[-Math.PI, 0.272, -Math.PI]}
          scale={[0.581, 0.767, 0.177]}
        />

        {/* Bilderrahmen */}
        <mesh
          name='Bilderrahmen'
          geometry={nodes.Bilderrahmen.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedPallet,
            })
          }
          position={[15.546, 16.689, -19.245]}
          rotation={[0, -0.638, 0]}
          scale={[0.997, 1.423, 0.035]}
        />

        {/* Gojo2 */}
        <mesh
          name='Gojo2'
          geometry={nodes.Gojo2.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedGojoKlein,
            })
          }
          position={[9.772, 15.862, -19.14]}
          rotation={[0, -0.476, 0]}
          scale={0.088}
        />

        <mesh
          name='BookZukko'
          geometry={nodes.BookZukko.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedHardcover3,
            })
          }
          position={[15.054, 13.223, -19.37]}
          rotation={[0, 0.881, -0.535]}
          scale={0.676}
        />

        {/* Hardcover1 */}
        <mesh
          name='Hardcover1'
          geometry={nodes.Hardcover1.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedHardcover1,
            })
          }
          position={[14.38, 9.911, -17.176]}
        />

        {/* Hardcover2 */}
        <mesh
          name='Hardcover2'
          geometry={nodes.Hardcover2.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedHardcover2,
            })
          }
          position={[14.551, 9.911, -17.176]}
        />

        {/* Hardcover3 */}
        <mesh
          name='Hardcover3'
          geometry={nodes.Hardcover3.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedHardcover2,
            })
          }
          position={[14.716, 9.911, -17.176]}
        />

        {/* Hardcover4 */}
        <mesh
          name='Hardcover4'
          geometry={nodes.Hardcover4.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedHardcover2,
            })
          }
          position={[14.885, 9.911, -17.176]}
        />

        {/* Wandregal */}
        <mesh
          name='Wandregal'
          geometry={nodes.Wandregal.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedTable,
            })
          }
          position={[14.855, 9.911, -17.176]}
          scale={[0.923, 0.58, 0.58]}
        />

        {/* Keyboard */}
        <mesh
          name='Keyboard'
          geometry={nodes.Keyboard.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedKeyboard,
            })
          }
          position={[6.267, 8.045, -16.202]}
        />

        {/* Mousepad */}
        <mesh
          name='Mousepad'
          geometry={nodes.Mousepad.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedMousepad,
            })
          }
          position={[8.012, 7.658, -16.169]}
        />

        {/* Macbook */}
        <mesh
          name='Macbook'
          geometry={nodes.Macbook.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedMacbook,
            })
          }
          position={[3.156, 7.996, -17.558]}
          rotation={[Math.PI / 2, -0.011, -Math.PI / 2]}
          scale={[1.572, 0.03, 1.433]}
        />

        {/* DeskStuff */}
        <mesh
          name='DeskStuff'
          geometry={nodes.DeskStuff.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedTableStuff,
            })
          }
          position={[3.161, 7.928, -15.24]}
          scale={0.138}
        />

        {/* Headset */}
        <mesh
          name='GojoHeadset'
          geometry={nodes.GojoHeadset.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedHeadset,
            })
          }
          position={[13.784, 6.391, -14.318]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
          scale={0.683}
        />

        {/* Holder */}
        <mesh
          name='HeadsetHolder'
          geometry={nodes.HeadsetHolder.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedHeadsetHolder,
            })
          }
          position={[15.173, 6.852, -14.063]}
          scale={0.341}
        />
        {/* Carpet */}
        <mesh
          name='Carpet'
          geometry={nodes.Carpet.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedCarpet,
            })
          }
          position={[8, 2.108, 8]}
        />

        {/* SchrankLinks */}
        <mesh
          name='SchrankLinks'
          geometry={nodes.SchrankLinks.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedSchrankGross,
            })
          }
          position={[-8.455, 1.671, -16.881]}
        />

        {/* GamingPC */}
        <mesh
          name='GamingPC'
          geometry={nodes.GamingPC.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedPC,
            })
          }
          position={[14, 9.7, -17.346]}
        />

        {/* PC Scheibe */}
        <mesh
          name='PCScheibe'
          geometry={nodes.PCScheibe.geometry}
          material={glassMaterialPC}
          position={[13, 9.7, -17.5]}
          rotation={[0, 0, -Math.PI / 2]}
        />

        {/* Schrank */}
        <mesh
          name='Schraenke'
          geometry={nodes.Schraenke.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedSchraenke,
            })
          }
          position={[18.253, 1.634, 8.101]}
        />

        {/* Fliesse (Floor Tiles) */}
        <mesh
          name='Fliesse'
          geometry={nodes.Fliesse.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedFliesse,
            })
          }
          position={[0.053, 1.752, -0.092]}
        />

        {/* Bilder */}
        <mesh
          name='Bilder'
          geometry={nodes.Bilder.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedPictures,
            })
          }
          position={[0.838, 18.1, -21.208]}
        />
        {/* Invisible mesh on top of the monitor for click detection */}
        <mesh
          ref={monitorRef}
          position={[7.8, 11.7, -19.5]} // Adjust position in your scene
          onClick={handleMonitorClick}
          onPointerOver={(e) => {
            document.body.style.cursor = "pointer";
          }}
          onPointerOut={(e) => {
            document.body.style.cursor = "auto";
          }}
        >
          <planeGeometry args={[9, 5]} />
          <meshBasicMaterial
            attach='material'
            color='transparent'
            transparent={true}
            opacity={0} // Invisible but clickable
          />

          {/* Moving 3D Text */}
          {!zoomedIn && (
            <Text
              font={fontMedium}
              position={[0, -0.25, 0.25]} // Adjust above the monitor
              fontSize={0.9} // Adjust size
              color='white'
              anchorX='center' // Center align the text horizontally
              anchorY='middle' // Center align the text vertically
              ref={textRef}
            >
              Click to Interact
            </Text>
          )}

          {/* Render the iframe as a texture on the plane */}
          <IFrameComponent
            isIOS={isIOS()}
            zoomedIn={zoomedIn}
            iframeVisible={iframeVisible}
            zoomSpriteRef={zoomSpriteRef}
            isMobile={isMobile}
            handleZoomSpriteClick={handleZoomSpriteClick}
            zoomMaterial={zoomMaterial}
          />
        </mesh>

        {/* Outside */}
        <mesh
          name='Outside'
          geometry={nodes.Outside.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedOutside,
            })
          }
          position={[19.9, 8.9, -8]}
          scale={[1, 1.1, 1.3]}
        />

        {/* LichtAussen */}
        <mesh
          name='LichtAussen'
          geometry={nodes.LichtAussen.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedLed,
              toneMapped: false,
              color: new Color(2.5, 2.54, 2.5),
            })
          }
          position={[0.838, 18.1, -21.208]}
        />

        {/* Ventil Links */}
        <mesh
          name='VentilLinksDrinne'
          geometry={nodes.VentilLinksDrinne.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedVentilMitte,
            })
          }
          position={[23.753, 18.571, 13.919]}
          rotation={[2.892, 0, 0]}
        />

        {/* Ventil Rechts */}
        <mesh
          name='VentilRechtsDrinne'
          geometry={nodes.VentilRechtsDrinne.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedVentilMitte,
            })
          }
          position={[23.753, 18.571, 6.66]}
          rotation={[2.926, 0, 0]}
        />

        {/* Spiegel */}
        <mesh
          name='Spiegel'
          geometry={nodes.Spiegel.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedSpiegel,
            })
          }
          position={[7.9, 10, 18.3]}
          rotation={[-2.967, 0, -Math.PI]}
        />

        {/* WandTeile */}
        <mesh
          name='WandTeile'
          geometry={nodes.WandTeile.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedTiles,
            })
          }
          position={[21.054, 1.051, -21.388]}
        />

        {/* Vasen */}
        <mesh
          name='Vasen'
          geometry={nodes.Vasen.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedVasen,
            })
          }
          position={[18.5, 10.7, 10.8]}
        />

        {/* Parfuem */}
        <mesh
          name='Parfuem'
          geometry={nodes.Parfuem.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedTomFord,
            })
          }
          position={[18, 10.2, 2]}
        />

        {/* Getrankemaschine */}
        <mesh
          name='Getrankemaschine'
          geometry={nodes.Getrankemaschine.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedDrinkAutomat,
            })
          }
          position={[-13.78, 0.594, -23.721]}
        />

        {/* GetrankemaschineGlas */}
        <mesh
          name='GetrankemaschineGlas'
          geometry={nodes.GetrankemaschineGlas.geometry}
          material={glassMaterialOutside}
          position={[-13.78, 4.23, -24.106]}
        />

        {/* Cola */}
        <mesh
          name='Cola'
          geometry={nodes.Cola.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedCola,
            })
          }
          position={[-12.3, 8.904, -23.8]}
        />

        {/* RedBullYellow */}
        <mesh
          name='RedBullYellow'
          geometry={nodes.RedBullYellow.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedRedBullYellow,
            })
          }
          position={[-11.798, 6.721, -23.969]}
        />

        {/* RedBullGreen */}
        <mesh
          name='RedBullGreen'
          geometry={nodes.RedBullGreen.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedRedBullGreen,
            })
          }
          position={[-12.798, 6.721, -23.969]}
        />

        {/* RedBullBlue */}
        <mesh
          name='RedBullBlue'
          geometry={nodes.RedBullBlue.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedRedBullBlue,
            })
          }
          position={[-13.798, 6.721, -23.969]}
        />

        {/* RedBullDarkBlue */}
        <mesh
          name='RedBullDarkBlue'
          geometry={nodes.RedBullDarkBlue.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedRedBullDarkBlue,
            })
          }
          position={[-14.97, 6.721, -23.969]}
        />

        {/* RedBullPurple */}
        <mesh
          name='RedBullPurple'
          geometry={nodes.RedBullPurple.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedRedBullPurple,
            })
          }
          position={[-15.859, 6.721, -23.969]}
        />

        {/* Fanta */}
        <mesh
          name='Fanta'
          geometry={nodes.Fanta.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedFanta,
            })
          }
          position={[-13.3, 8.904, -23.8]}
        />

        {/* ElephantBay */}
        <mesh
          name='ElephantBay'
          geometry={nodes.ElephantBay.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedElephantBay,
            })
          }
          position={[-14.3, 8.904, -23.8]}
        />

        {/* Karamalz */}
        <mesh
          name='Karamalz'
          geometry={nodes.Karamalz.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedKaramalz,
            })
          }
          position={[-15.3, 8.904, -23.8]}
        />

        {/* Jukebox */}
        <mesh
          name='Jukebox'
          geometry={nodes.Jukebox.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedJukebox,
            })
          }
          position={[13.573, 0.676, -23.576]}
        />

        {/* AlbumAbel */}
        <mesh
          name='AlbumAbel'
          geometry={nodes.AlbumAbel.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedAlbum3,
            })
          }
          position={[17.497, 0.735, -23.447]}
        />

        {/* AlbumTarkan */}
        <mesh
          name='AlbumTarkan'
          geometry={nodes.AlbumTarkan.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedAlbum2,
            })
          }
          position={[18.353, 0.735, -23.18]}
        />

        {/* AlbumJustin */}
        <mesh
          name='AlbumJustin'
          geometry={nodes.AlbumJustin.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedAlbum1,
            })
          }
          position={[19.226, 0.735, -22.958]}
        />

        {/* Schild */}
        <mesh
          name='Schild'
          geometry={nodes.Schild.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedSchild,
              toneMapped: false,
              color: new Color(2.5, 2.54, 2.5),
            })
          }
          position={[21.4, 17.3, -7.9]}
        />

        {/* Taschenlampe */}
        <mesh
          name='Taschenlampe'
          geometry={nodes.Taschenlampe.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedTaschenlampe,
            })
          }
          position={[21.287, 0.644, -27.572]}
        />

        {/* Markise */}
        <mesh
          name='Markise'
          geometry={nodes.Markise.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedMarkise,
            })
          }
          position={[1, 0.57, -31.513]}
        />

        {/* WandAußen */}
        <mesh
          name='WandAußen'
          geometry={nodes.WandAußen.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedWallsOutside,
            })
          }
          position={[0.454, 0.7, -0.473]}
        />

        {/* Bed */}
        <mesh
          name='Bed'
          geometry={nodes.Bed.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedBed,
            })
          }
          position={[-4.816, 5.043, 14.25]}
        />

        {/* PCFan */}
        <mesh
          name='PCFan'
          geometry={nodes.PCFan.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedPCFan,
            })
          }
          position={[14.1, 10.6, -19.3]}
        />

        {/* Fan4 */}
        <mesh
          name='Fan4'
          geometry={nodes.Fan4.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedFanPart,
            })
          }
          position={[14.094, 10.59, -19.006]}
          rotation={[-Math.PI, 0, Math.PI / 2]}
          scale={-1}
        />

        {/* Arcade */}
        <mesh
          name='Arcade'
          geometry={nodes.Arcade.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedArcade,
            })
          }
          position={[-5.366, 0.586, -25.954]}
        />

        {/* ArcadeSticks */}
        <mesh
          name='ArcadeSticks'
          geometry={nodes.ArcadeSticks.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedArcadeButtons,
            })
          }
          position={[-5.865, 5.902, -29.65]}
        />

        {/* Stick */}
        <mesh
          name='Stick'
          geometry={nodes.Stick.geometry}
          material={
            new MeshBasicMaterial({
              map: textures.bakedArcadeButtons,
            })
          }
          position={[-3.857, 6.273, -29.714]}
          rotation={[-0.038, 0.08, 0.007]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/SetupIbrahim2.glb");
